import React from "react"

import Container from "../components/container/container"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Sivua ei löydy" />
    <Container>
      <div style={{paddingBottom : "6rem"}}>
        <h1>Sivua ei löydy</h1>
        <p><a style={{color : "#A88613"}} href="/">Palaa sivustolle</a></p>
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
 